html{
  margin: 0;
  padding: 0;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.fs-15 {
	font-size: 15px;
}

body::-webkit-scrollbar{
  display: none;
}

.attribution-text {
  font-size: xx-small;
  margin-top: -10px;
}

.attribution-text > a {
  color: #aaaaaa;
  font-weight: 100;
}





@media (max-width: 425px) {
  .maintenance {
    width: 90vw;
    max-width: 90vw;
  }

  .loader {
    width:50vw;
    max-width:50vw;
  }

  .error-text {
    margin: 20px;
    font-size: medium;
    font-weight: 100;
    text-align: center;
  }
}
@media (min-width: 425px) and (max-width: 768px){
  .maintenance {
    width: 40vh;
    max-width: 40vh;
  }

  .loader {
    width:20vh;
    max-width:20vh;
  }

  .error-text {
    margin: 20px;
    font-size: medium;
    font-weight: 100;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .maintenance {
    width: 45vh;
    max-width: 45vh;
  }

  .loader {
    width:25vh;
    max-width:25vh;
  }

  .error-text {
    margin: 20px;
    font-size: x-large;
    font-weight: 100;
    text-align: center;
  }
}