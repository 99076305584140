*{
	font-family: 'Montserrat', sans-serif;
}

.app{
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden !important;

}

.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
	width: 100%;
	z-index: 99999;
	align-items: center;
	/* background-image: url("./assets/loader.png");
	background-size: contain;
    background-position: bottom;
	background-repeat: no-repeat; */
	height: 90%;
	position: relative;
}

.lettering {
	padding-top: 1%;
	width: 12%;
	margin: auto;
	text-align: center;
}

.pictogram {
	position: absolute;
	width: 70%;
	bottom: 0;
	left: 0;
	z-index: 1;
	opacity: 0.5;
}


@media (min-width: 0px) and (max-width: 575.98px) { 
	.pictogram {
		width: 100%;
	}

	.lettering {
		padding-top: 4%;
		width: 40%;
	}
}

@media (min-width: 575.99px) and (max-width: 767.98px) { 
	.pictogram {
		width: 100%;
	}

	.lettering {
		padding-top: 4%;
		width: 40%;
	}
}

@media (min-width: 767.99px) and (max-width: 991.98px) {
	.pictogram {
		width: 100%;
	}

	.lettering {
		padding-top: 4%;
		width: 30%;
	}
}

@media (min-width: 991.99px) and (max-width: 1199.98px) { 
	.pictogram {
		width: 100%;
	}

	.lettering {
		padding-top: 4%;
		width: 30%;
	}
}

@media (min-width: 1199.99px) and (max-width: 1399.98px) { 
}
