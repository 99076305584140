.footer {
	background-color: #E6E6E6;;
    /* width: 100vw; */
	z-index: 999999999999;
    padding: 12px 0px 12px 0px;
}

a{
	text-decoration: none;
	color: black;
}